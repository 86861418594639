import type { PostgrestError, SupabaseClient } from "@supabase/supabase-js";
import { useInfiniteQuery, useQuery } from "@tanstack/vue-query";
import type {
  InfiniteData,
  QueryKey,
  UseInfiniteQueryOptions,
  UseInfiniteQueryReturnType,
  UseQueryOptions,
  UseQueryReturnType,
} from "@tanstack/vue-query";

type UnwrapRef<T> = T extends Ref<infer U> ? U : T;
type QueryBuilder = ReturnType<SupabaseClient["from"]>;
type RpcBuilder = ReturnType<SupabaseClient["rpc"]>;
type SelectedQuery = ReturnType<QueryBuilder["select"]>;
type LimitedQuery = ReturnType<SelectedQuery["limit"]>;
type RpcSelectedQuery =
  | ReturnType<RpcBuilder["select"]>
  | ReturnType<ReturnType<RpcBuilder["select"]>["maybeSingle"]>;
type SingleSelectedQuery =
  | ReturnType<ReturnType<QueryBuilder["select"]>["maybeSingle"]>
  | ReturnType<ReturnType<QueryBuilder["select"]>["single"]>;

type GeneralSupabaseQuery =
  | RpcSelectedQuery
  | SelectedQuery
  | SingleSelectedQuery;

type SupabaseQueryProps<
  InnerQuery extends GeneralSupabaseQuery,
  TQueryFnData,
  TError,
  TData,
  TQueryData,
  TQueryKey extends QueryKey = QueryKey
> = {
  queryFn: (client: ReturnType<typeof useClient>) => InnerQuery;
  queryKey: MaybeRefOrGetter<QueryKey>;
} & Omit<
  UnwrapRef<
    UseQueryOptions<TQueryFnData, TError, TData, TQueryData, TQueryKey>
  >,
  "queryKey" | "queryFn"
>;

type InfiniteSupabaseQueryProps<
  TQueryFnData extends unknown,
  TError = PostgrestError,
  TData = TQueryFnData,
  TQueryData = TQueryFnData,
  InnerQuery extends SelectedQuery = SelectedQuery,
  TQueryKey extends QueryKey = QueryKey,
  TPageParam = string
> = {
  queryFn: (client: ReturnType<typeof useClient>) => InnerQuery;
  queryKey: MaybeRefOrGetter<QueryKey>;
  ordering: Ordering;
} & Omit<
  UnwrapRef<
    UseInfiniteQueryOptions<
      TQueryFnData,
      TError,
      TData,
      TQueryData,
      TQueryKey,
      TPageParam
    >
  >,
  "queryKey" | "queryFn" | "initialPageParam" | "getNextPageParam"
>;

type direction = "asc" | "desc";
type Ordering =
  | [[string, direction]]
  | [[string, direction], [string, direction]];

export async function expectData<E, T>(
  p: PromiseLike<{ error: E; data: null } | { data: T; error: null }>
): Promise<T> {
  const { data, error } = await p;
  if (error) throw error;
  if (!data) throw new Error("No data");
  return data;
}

export function useSupabaseQuery<
  InnerQuery extends
    | SelectedQuery
    | SingleSelectedQuery
    | LimitedQuery
    | RpcSelectedQuery,
  TQueryFnData = Awaited<InnerQuery>["data"],
  TError = Error,
  TData = TQueryFnData,
  TQueryData = TQueryFnData,
  TQueryKey extends QueryKey = QueryKey
>({
  queryFn,
  queryKey,
  ...rest
}: SupabaseQueryProps<
  InnerQuery,
  TQueryFnData,
  TError,
  TData,
  TQueryData,
  TQueryKey
>): UseQueryReturnType<TData, TError> {
  const supabase = useSupabaseClient();
  return useQuery({
    ...rest,
    // @ts-expect-error - I have no idea why this doesn't work.
    queryKey: computed(() => toValue(queryKey)),
    queryFn: async () => {
      const result = await queryFn(supabase);
      if (result.error) throw result.error;
      return result.data as TQueryFnData;
    },
  }) as any;
}

type Paginator<T extends SelectedQuery> = (
  pageParam: string | undefined,
  unpaginated: T
) => T;

export type SupabaseInfiniteQueryReturnType<
  TData extends InfiniteData<unknown>,
  TError
> = {
  [Property in keyof UseInfiniteQueryReturnType<
    TData,
    TError
  >]: Property extends "data"
    ? Ref<NonNullable<TData["pages"][number]>>
    : UseInfiniteQueryReturnType<TData, TError>[Property];
};

export function useSupabaseInfiniteQuery<
  InnerQuery extends SelectedQuery,
  TQueryFnData = NonNullable<Awaited<InnerQuery>["data"]>,
  TError = Error,
  TData extends InfiniteData<TQueryFnData> = {
    pages: TQueryFnData[];
    pageParams: any;
  },
  TQueryData = TQueryFnData,
  TQueryKey extends QueryKey = QueryKey
>({
  ordering,
  queryFn,
  queryKey,
  ...rest
}: InfiniteSupabaseQueryProps<
  TQueryFnData,
  TError,
  TData,
  TQueryData,
  InnerQuery,
  TQueryKey,
  string
>): SupabaseInfiniteQueryReturnType<TData, TError> {
  const supabase = useSupabaseClient();

  function querify(ordering: Ordering, cursor: [string, string][]): string {
    const orParts = ordering.map((_item, index, ordering) => {
      const andParts = ordering
        .slice(0, index + 1)
        .map(
          ([_column, direction], i) =>
            `${cursor[i][0]}.${
              i == index ? (direction === "asc" ? "gt" : "lt") : "eq"
            }.${cursor[i][1]}`
        );
      if (andParts.length == 1) return andParts[0];
      return `and(${andParts.join(",")})`;
    });
    return orParts.join(",");
  }

  function paginationParam(...params: [string, unknown][]): string {
    return btoa(JSON.stringify(params));
  }

  function paginate<T extends SelectedQuery>(
    pageParam: string | undefined,
    unpaginated: T
  ): T {
    if (!pageParam) return unpaginated;
    const cursor = JSON.parse(atob(pageParam)) as [string, string][];

    return unpaginated.or(querify(ordering, cursor));
  }

  const { data, ...returnRest } = useInfiniteQuery({
    ...rest,
    // @ts-expect-error - I have no idea why this doesn't work.
    queryKey: computed(() => toValue(queryKey)),
    getNextPageParam: (lastPage, _pages) => {
      if (!lastPage || !Array.isArray(lastPage) || lastPage.length === 0)
        return undefined;
      const unpacked = ordering.map(([key, _direction]) => {
        const value = lastPage.at(-1)[key];
        return [key, value];
      }) as [string, string][];
      return paginationParam(...unpacked);
    },
    queryFn: async ({ pageParam }) => {
      let q = queryFn(supabase);
      for (const o of ordering) {
        q = q.order(o[0], { ascending: o[1] === "asc" });
      }
      const result = await paginate(pageParam, q);
      if (result.error) throw result.error;
      return result.data as TQueryFnData;
    },
    // I don't understand why I need to supply any here for this to pass:
    initialPageParam: "" as any,
  });

  return {
    ...returnRest,
    data: computed(() => data.value?.pages?.flat()),
  };
}
